// Create annotation member
export const POST_REACTION = 'POST_REACTION';
export const PUT_REACTION = 'PUT_REACTION';
export const POSTED_REACTION = 'POSTED_REACTION';
export const REACTION_UPDATED = 'REACTION_UPDATED';
export const FAILED_POST_REACTION = 'FAILED_POST_REACTION';

export const newReaction = (message, annotation, onFinish) => ({
  type: POST_REACTION,
  payload: { message, respondsTo: annotation['@id'] },
  callback: onFinish
});

export const postedReaction = payload => ({
  type: POSTED_REACTION,
  payload
});

export const updateReaction = (reaction, cleanUp) => ({
  type: PUT_REACTION,
  payload: reaction,
  callback: cleanUp
});

export const reactionUpdated = reaction => ({
  type: REACTION_UPDATED,
  payload: reaction
});

export const failedPostReaction = payload => ({
  type: FAILED_POST_REACTION,
  payload
});

export const DELETE_REACTION = 'DELETE_REACTION';
export const DELETED_REACTION = 'DELETED_REACTION';
export const FAILED_DELETED_REACTION = 'FAILED_DELETED_REACTION';

export const removeReaction = reaction => ({
  type: DELETE_REACTION,
  payload: reaction
});

export const deletedReaction = payload => ({
  type: DELETED_REACTION,
  payload
});

export const failedDeleteReaction = payload => ({
  type: FAILED_DELETED_REACTION,
  payload
});
