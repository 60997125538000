import {
  FAILED_POST_REACTION,
  POST_REACTION,
  POSTED_REACTION,
  REACTION_UPDATED
} from 'actions/reactionActions';

export const initialState = {
  posting: false,
  error: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case POST_REACTION:
      return {
        posting: true,
        error: null
      };
    case POSTED_REACTION:
    case REACTION_UPDATED:
      return {
        posting: false,
        error: null
      };
    case FAILED_POST_REACTION:
      return {
        posting: false,
        error: action.payload
      };
    default:
      return state;
  }
}
