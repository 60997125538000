export function remove(state, element, key = 'id') {
  if (Array.isArray(state)) {
    return state.filter(el => el !== element);
  }
  const stateKey = element[key];
  delete state[stateKey];
  return state;
}

export function indexBy(items, getKey) {
  const indexed = {};

  items.forEach(item => {
    indexed[getKey(item)] = item;
  });

  return indexed;
}

export function isFetching(item) {
  return item.fetching === true;
}

export function allFetched(items) {
  if (items && items.every(item => !isFetching(item))) {
    return items;
  }

  return { fetching: true };
}
