import { initialState as annotationInitialState } from 'reducers/annotationReducer';
import { initialState as recordingInitialState } from 'reducers/recordingReducer';
import { initialState as submissionInitialState } from 'reducers/submissionReducer';

const stores = {
  annotationInitialState,
  recordingInitialState,
  submissionInitialState
};

const clearedStoreByProperty = (property, state, initialState) => ({
  ...state,
  [property]: initialState[property]
});

export default function(appReducer, state, action) {
  if (action.type === 'CLEAR_STORE') {
    let store = `${action.store}InitialState`;

    state = {
      ...state,
      ...(!action.property ? { [action.store]: stores[store] } : {}),
      ...(action.property
        ? {
            [action.store]: clearedStoreByProperty(
              action.property,
              state[action.store],
              stores[store]
            )
          }
        : {})
    };
  }

  return appReducer(state, action);
}
