import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class LoginSuccess extends React.Component {
  componentDidMount() {
    const { token } = this.props.match.params;

    this.props.dispatch({ type: 'TOKEN_RECEIVED', payload: { token } });
  }

  render() {
    return <Redirect to="/recordings" />;
  }
}

export default connect()(LoginSuccess);
