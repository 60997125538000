import annotationSaga from 'sagas/annotationSagas';
import recordingSaga from 'sagas/recordingSagas';
import submissionSaga from 'sagas/submissionSagas';
import tokenSaga from 'sagas/login';
import userSaga from 'sagas/users';
import videoSaga from 'sagas/videoSagas';
import supervisorSaga from 'sagas/supervisorSagas';
import reactionSaga from 'sagas/reactionSagas';
import portfolioSaga from 'sagas/portfolioSagas';
import filterSaga from 'sagas/filterSagas';

const sagas = [
  annotationSaga,
  recordingSaga,
  submissionSaga,
  tokenSaga,
  userSaga,
  videoSaga,
  supervisorSaga,
  reactionSaga,
  portfolioSaga,
  filterSaga
];

export default sagas;
