import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Routes from 'components/Routes';
import { appStarted } from '../../actions/appActions';

const mapStateToProps = (state, ownProps = {}) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    init: () => dispatch(appStarted())
  };
};

// App container must have withRouter
const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);

export default withRouter(AppContainer);
