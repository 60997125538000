export const REQUEST_GET_SUPERVISORS = 'REQUEST_GET_SUPERVISORS';
export const RECEIVE_GET_SUPERVISORS = 'RECEIVE_GET_SUPERVISORS';
export const FAILED_GET_SUPERVISORS = 'FAILED_GET_SUPERVISORS';

// Get supervisor collection
export const receiveGetSupervisors = payload => ({
  type: RECEIVE_GET_SUPERVISORS,
  payload
});

export const failedGetSupervisors = payload => ({
  type: FAILED_GET_SUPERVISORS,
  payload
});
