export const REQUEST_GET_VIDEO = 'REQUEST_GET_VIDEO';
export const RECEIVE_GET_VIDEO = 'RECEIVE_GET_VIDEO';
export const FAILED_GET_VIDEO = 'FAILED_GET_VIDEO';

export const receiveGetVideo = payload => ({
  type: RECEIVE_GET_VIDEO,
  payload
});

export const failedGetVideo = payload => ({
  type: FAILED_GET_VIDEO,
  payload
});

// Delete video member
export const REQUEST_DELETE_VIDEO = 'REQUEST_DELETE_VIDEO';
export const RECEIVE_DELETE_VIDEO = 'RECEIVE_DELETE_VIDEO';
export const FAILED_DELETE_VIDEO = 'FAILED_DELETE_VIDEO';

export const receiveDeleteVideo = payload => ({
  type: RECEIVE_DELETE_VIDEO,
  payload
});

export const failedDeleteVideo = payload => ({
  type: FAILED_DELETE_VIDEO,
  payload
});
