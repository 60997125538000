import {
  FAILED_GET_ANNOTATIONS,
  RECEIVE_CREATE_ANNOTATION,
  RECEIVE_DELETE_ANNOTATION,
  RECEIVE_GET_ANNOTATIONS,
  REQUEST_GET_ANNOTATIONS
} from 'actions/annotationActions';
import {
  DELETED_REACTION,
  POSTED_REACTION,
  REACTION_UPDATED
} from 'actions/reactionActions';
import { indexBy } from 'reducers/helpers';
import {} from '../actions/reactionActions';

export const initialState = {
  collection: {},
  fetching: true,
  error: null
};

const updateAnnotation = (state, annotation) => {
  return {
    ...state,
    collection: {
      ...state.collection,
      [annotation.id]: annotation
    }
  };
};

const addReaction = (state, reaction) => {
  const annotation = Object.values(state.collection).find(
    item => item['@id'] === reaction.respondsTo
  );
  return {
    ...annotation,
    reactions: [...annotation.reactions, reaction]
  };
};

const updateReaction = (state, reaction) => {
  const annotation = Object.values(state.collection).find(item =>
    item.reactions.find(
      knownReaction => knownReaction['@id'] === reaction['@id']
    )
  );
  return {
    ...annotation,
    reactions: annotation.reactions.map(knownReaction => {
      if (knownReaction['@id'] === reaction['@id']) {
        return reaction;
      }
      return knownReaction;
    })
  };
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case POSTED_REACTION:
      return updateAnnotation(state, addReaction(state, action.payload));

    case REACTION_UPDATED:
      return updateAnnotation(state, updateReaction(state, action.payload));

    case DELETED_REACTION:
      return {
        ...state,
        collection: Object.fromEntries(
          Object.entries(state.collection).map(([index, annotation]) => {
            return [
              index,
              {
                ...annotation,
                reactions: annotation.reactions.filter(
                  reaction => reaction['@id'] !== action.payload['@id']
                )
              }
            ];
          })
        )
      };

    case RECEIVE_DELETE_ANNOTATION:
      const newState = {
        ...state
      };
      delete newState.collection[action.annotationId];
      return newState;
    case REQUEST_GET_ANNOTATIONS:
      return {
        ...state,
        collection: {
          ...state.collection
        },
        error: null,
        fetching: true
      };
    case RECEIVE_GET_ANNOTATIONS:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...indexBy(action.payload, item => item.id)
        },
        error: null,
        fetching: false
      };
    case FAILED_GET_ANNOTATIONS:
      return {
        ...state,
        collection: {
          ...state.collection
        },
        error: action.payload,
        fetching: false
      };
    case RECEIVE_CREATE_ANNOTATION:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload
        },
        error: null,
        fetching: false
      };
    default:
      return state;
  }
}
