import {
  DELETE_REACTION,
  deletedReaction,
  failedPostReaction,
  POST_REACTION,
  postedReaction,
  PUT_REACTION,
  reactionUpdated
} from 'actions/reactionActions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import hydraParser from 'service/hydraParser';
import { makeSecureApiCall } from './api';

function* submitReaction(action) {
  try {
    const reaction = yield call(
      makeSecureApiCall,
      `/api/reactions`,
      'POST',
      action.payload
    );
    const parsed = hydraParser.parseItem(reaction);
    yield put(postedReaction(parsed));
    action.callback();
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedPostReaction(parsed));
  }
}

function* updateReaction(action) {
  try {
    const reaction = yield call(
      makeSecureApiCall,
      action.payload['@id'],
      'PUT',
      action.payload
    );
    const parsed = hydraParser.parseItem(reaction);
    yield put(reactionUpdated(parsed));
    action.callback();
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedPostReaction(parsed));
  }
}

function* deleteReaction(action) {
  try {
    yield call(makeSecureApiCall, action.payload['@id'], 'DELETE');
    yield put(deletedReaction(action.payload));
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedPostReaction(parsed));
  }
}

export default function* reactionSaga() {
  yield all([
    yield takeLatest(POST_REACTION, submitReaction),
    yield takeLatest(PUT_REACTION, updateReaction),
    yield takeLatest(DELETE_REACTION, deleteReaction)
  ]);
}
