import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  failedCreateAnnotation,
  failedDeleteAnnotation,
  failedGetAnnotations,
  failedUpdateAnnotation,
  receiveCreateAnnotation,
  receiveDeleteAnnotation,
  receiveGetAnnotations,
  receiveUpdateAnnotation,
  RECEIVE_CREATE_ANNOTATION,
  RECEIVE_UPDATE_ANNOTATION,
  REQUEST_CREATE_ANNOTATION,
  REQUEST_DELETE_ANNOTATION,
  REQUEST_GET_ANNOTATIONS,
  REQUEST_UPDATE_ANNOTATION,
  RECEIVE_DELETE_ANNOTATION
} from 'actions/annotationActions';
import hydraParser from 'service/hydraParser';
import { makeSecureApiCall } from './api';

function* getAnnotations(action) {
  try {
    const annotation = yield call(
      makeSecureApiCall,
      `/api/recordings/${action.recordingId}/annotations`
    );
    const parsed = hydraParser.parseCollection(annotation);
    yield put(receiveGetAnnotations(parsed));
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedGetAnnotations(parsed));
  }
}

function* createAnnotation(action) {
  try {
    const annotation = yield call(
      makeSecureApiCall,
      `/api/annotations`,
      'POST',
      action.data
    );
    const parsed = hydraParser.parseItem(annotation);
    yield put(receiveCreateAnnotation(parsed));
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedCreateAnnotation(parsed));
  }
}

function* updateAnnotation(action) {
  try {
    const annotation = yield call(
      makeSecureApiCall,
      `/api/annotations/${action.annotationId}`,
      'PUT',
      action.data
    );
    const parsed = hydraParser.parseItem(annotation);
    yield put(receiveUpdateAnnotation(parsed));
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedUpdateAnnotation(parsed));
  }
}

function* deleteAnnotation(action) {
  try {
    yield call(
      makeSecureApiCall,
      `/api/annotations/${action.annotationId}`,
      'DELETE'
    );
    yield put(receiveDeleteAnnotation(action.annotationId));
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedDeleteAnnotation(parsed));
  }
}

export default function* annotationSaga() {
  yield all([
    yield takeLatest(RECEIVE_CREATE_ANNOTATION, getAnnotations),
    yield takeLatest(RECEIVE_UPDATE_ANNOTATION, getAnnotations),
    yield takeLatest(RECEIVE_DELETE_ANNOTATION, getAnnotations),
    yield takeLatest(REQUEST_GET_ANNOTATIONS, getAnnotations),
    yield takeLatest(REQUEST_CREATE_ANNOTATION, createAnnotation),
    yield takeLatest(REQUEST_DELETE_ANNOTATION, deleteAnnotation),
    yield takeLatest(REQUEST_UPDATE_ANNOTATION, updateAnnotation)
  ]);
}
