import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  failedGetSupervisors,
  receiveGetSupervisors,
  REQUEST_GET_SUPERVISORS
} from 'actions/supervisorActions';
import hydraParser from 'service/hydraParser';
import { makeSecureApiCall } from './api';

function* getSupervisors() {
  try {
    const supervisors = yield call(makeSecureApiCall, `/api/users/supervisors`);
    const parsed = hydraParser.parseCollection(supervisors);
    yield put(receiveGetSupervisors(parsed));
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedGetSupervisors(parsed));
  }
}

export default function* supervisorSaga() {
  yield all([yield takeLatest(REQUEST_GET_SUPERVISORS, getSupervisors)]);
}
