import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

// Import App component
import App from 'containers/App';

// Import configs
import store from 'config/store';
import history from 'config/history';
import * as serviceWorker from 'config/serviceWorker';
import * as moment from 'moment';
import 'moment/locale/nl';

// Import global styles
import './assets/global.scss';
import 'react-table-filter/lib/styles.css';

moment.locale('nl');

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (process.env.NODE_ENV === 'production') serviceWorker.register();
else serviceWorker.unregister();
