import Button from 'components/Button';
import React from 'react';
import { connect } from 'react-redux';

import './login.scss';
import Navigation from 'components/Navigation';

export default function LoginRequiredContainer(WrappedComponent) {
  const login = dispatch => {
    dispatch({ type: 'TOKEN_REQUIRED' });
  };

  const LoginRequired = props => {
    const { user, dispatch } = props;

    if (user === null) {
      dispatch({
        type: 'CLEAR_STORE',
        store: 'recording',
        property: 'collection'
      });
      dispatch({
        type: 'CLEAR_STORE',
        store: 'recording',
        property: 'uploads'
      });
      dispatch({
        type: 'CLEAR_STORE',
        store: 'recording',
        property: 'reviews'
      });
      return (
        <div className="login-page">
          <Navigation user={null} backButton={null} logout={null} />
          <div className="not-logged-in">
            <h1>Inloggen</h1>
            <Button variant="btn-login" onClick={() => login(dispatch)}>
              Inloggen
            </Button>
            <p className="no-account">nog geen account?</p>
          </div>
        </div>
      );
    }

    return <WrappedComponent user={user} {...props} />;
  };

  const mapStateToProps = state => {
    return {
      user: state.login.user
    };
  };

  return connect(mapStateToProps)(LoginRequired);
}
