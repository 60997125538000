// SEND submission member

export const REQUEST_SEND_PORTFOLIO = 'REQUEST_SEND_PORTFOLIO';
export const RECEIVE_SEND_PORTFOLIO = 'RECEIVE_SEND_PORTFOLIO';
export const FAILED_SEND_PORTFOLIO = 'FAILED_SEND_PORTFOLIO';

export const receiveSendPortfolio = payload => ({
  type: RECEIVE_SEND_PORTFOLIO,
  payload
});

export const failedSendPortfolio = payload => ({
  type: FAILED_SEND_PORTFOLIO,
  payload
});
