const FILTER_KEY = 'filters';

export default class Session {
  static loadFilters() {
    return JSON.parse(sessionStorage.getItem(FILTER_KEY));
  }

  static saveFilters(filters) {
    sessionStorage.setItem(FILTER_KEY, JSON.stringify(filters));
  }
}
