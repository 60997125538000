import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { USER_REQUIRED, userLoaded } from 'actions/userActions';
import { makeSecureApiCall } from './api';
import { userRequested, userRequired } from '../actions/userActions';
import { RECEIVE_GET_SUBMISSIONS } from '../actions/submissionActions';
import { RECEIVE_REVIEWS } from '../actions/recordingActions';

function* getUser(action) {
  const { userId } = action.payload;
  const user = yield select(state => {
    return state.users[userId];
  });

  if (!user || user.fetching === false) {
    yield put(userRequested(userId));
    const userData = yield call(makeSecureApiCall, userId);
    if (userData) {
      yield put(userLoaded(userData));
    }
  }
}

function* getUploader(action) {
  for (let recording of action.payload) {
    yield put(userRequired(recording.createdBy));
  }
}

function* getSupervisor(action) {
  for (let submission of action.payload) {
    yield put(userRequired(submission.supervisor));
  }
}

export default function* userSaga() {
  yield all([
    yield takeEvery(USER_REQUIRED, getUser),
    yield takeEvery(RECEIVE_REVIEWS, getUploader),
    yield takeEvery(RECEIVE_GET_SUBMISSIONS, getSupervisor)
  ]);
}
