
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { makeSecureApiCall } from 'sagas/api';
import api from 'service/invidii-api';
import loginActions from '../actions/loginActions';

function* login() {
  const callback =
    window.location.protocol +
    '//' +
    window.location.hostname +
    ':' +
    window.location.port +
    '/login-success/';
  yield put({ type: 'TOKEN_REQUESTED' });
  console.log('/oauth/auth?redirect_url=' + callback);
  api.open('/oauth/auth?redirect_url=' + callback);
}

export function* logout() {
  try {
    yield call(makeSecureApiCall, `/api/logout`);
    yield put({ type: 'TOKEN_REMOVED' });
  } catch (error) {
    console.log(error);
  }
}

export default function* tokenSaga() {
  yield all([
    yield takeLatest('TOKEN_REQUIRED', login),
    yield takeLatest(loginActions.LOGOUT, logout)
  ]);
}
