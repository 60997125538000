// Create submission member

export const REQUEST_CREATE_SUBMISSION = 'REQUEST_CREATE_SUBMISSION';
export const RECEIVE_CREATE_SUBMISSION = 'RECEIVE_CREATE_SUBMISSION';
export const FAILED_CREATE_SUBMISSION = 'FAILED_CREATE_SUBMISSION';
export const SUBMIT_REVIEW = 'SUBMIT_REVIEW';
export const REVIEW_COMPLETE = 'REVIEW_COMPLETE';
export const REVIEW_SUBMISSION_FAILED = 'REVIEW_SUBMISSION_FAILED';

export const receiveCreateSubmission = payload => ({
  type: RECEIVE_CREATE_SUBMISSION,
  payload
});

export const failedCreateSubmission = payload => ({
  type: FAILED_CREATE_SUBMISSION,
  payload
});

// Get submissions collection

export const REQUEST_GET_SUBMISSIONS = 'REQUEST_GET_SUBMISSIONS';
export const loadSubmissions = recordingId => {
  return {
    type: REQUEST_GET_SUBMISSIONS,
    recordingId
  };
};

export const RECEIVE_GET_SUBMISSIONS = 'RECEIVE_GET_SUBMISSIONS';
export const FAILED_GET_SUBMISSIONS = 'FAILED_GET_SUBMISSIONS';

export const receiveGetSubmissions = payload => ({
  type: RECEIVE_GET_SUBMISSIONS,
  payload
});

export const failedGetSubmissions = payload => ({
  type: FAILED_GET_SUBMISSIONS,
  payload
});

export function submitReview(submission, reviews) {
  return {
    type: SUBMIT_REVIEW,
    payload: {
      submission,
      reviews
    }
  };
}

export function reviewComplete(submission) {
  return {
    type: REVIEW_COMPLETE,
    payload: {
      submission
    }
  };
}

export function reviewSubmissionFailed(message) {
  return {
    type: REVIEW_SUBMISSION_FAILED,
    payload: message
  };
}

export const SUBMISSION_REQUIRED = 'SUBMISSION_REQUIRED';
export function submissionRequired(submissionId) {
  return {
    type: SUBMISSION_REQUIRED,
    payload: {
      submissionId
    }
  };
}

export const SUBMISSION_REQUESTED = 'SUBMISSION_REQUESTED';
export function submissionRequested(submissionId) {
  return {
    type: SUBMISSION_REQUESTED,
    payload: {
      submissionId
    }
  };
}
