import {
  REVIEW_SUBMISSION_FAILED,
  FAILED_GET_SUBMISSIONS,
  RECEIVE_GET_SUBMISSIONS,
  REQUEST_GET_SUBMISSIONS,
  FAILED_CREATE_SUBMISSION,
  REVIEW_COMPLETE,
  SUBMISSION_REQUESTED
} from 'actions/submissionActions';
import { indexBy } from './helpers';
import { RECEIVE_SUBMISSION } from '../actions/recordingActions';
import { RECEIVE_CREATE_SUBMISSION } from '../actions/submissionActions';

export const initialState = {
  collection: {},
  error: null,
  fetching: true
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_GET_SUBMISSIONS:
      return {
        ...state,
        error: null,
        fetching: true
      };

    case RECEIVE_GET_SUBMISSIONS:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...indexBy(action.payload, submission => submission['@id'])
        },
        error: null,
        fetching: false
      };

    case RECEIVE_SUBMISSION:
    case RECEIVE_CREATE_SUBMISSION:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload['@id']]: action.payload
        }
      };

    case REVIEW_SUBMISSION_FAILED:
    case FAILED_CREATE_SUBMISSION:
    case FAILED_GET_SUBMISSIONS:
      return {
        ...state,
        error: action.payload,
        fetching: false
      };

    case REVIEW_COMPLETE:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.submission['@id']]: action.payload.submission
        }
      };

    case SUBMISSION_REQUESTED:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.submissionId]: { fetching: true }
        }
      };

    default:
      return state;
  }
}
