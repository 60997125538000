import { applyMiddleware, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware, { END } from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';

// Import configs
import history from 'config/history';
import sagas from 'config/sagas';

// Import root reducer
import rootReducer from 'reducers/rootReducer';

// Dev tools
function runDevToolExtensionIfNotInProduction() {
  const shouldExposeState =
    (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') &&
    window.devToolsExtension;
  return shouldExposeState ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f;
}

// Add Middleware
const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  sagaMiddleware,
  routerMiddleware(history) // add browser history
];

// Enable redux logging in dev
if (process.env.NODE_ENV === 'development') {
  const loggerMiddleware = createLogger({
    collapsed: true
  });

  middlewares.push(loggerMiddleware);
}

// Get initialState for session token
const initialState = JSON.parse(localStorage.getItem('state')) || {};

// Create store
const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middlewares),
    runDevToolExtensionIfNotInProduction()
  )
);

// Configure Sagas
sagas.forEach(sagaMiddleware.run.bind(sagaMiddleware));
store.runSaga = sagaMiddleware.run;
store.close = () => store.dispatch(END);

export default store;
