import * as ErrorActions from '../actions/errorActions';
import { FAILED_CREATE_SUBMISSION } from '../actions/submissionActions';

const defaultState = null;

const errorReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ErrorActions.SERVER_ERROR:
      return {
        message: 'Er is iets misgegaan bij de communicatie met de server.'
      };

    case ErrorActions.ERROR_CLEARED:
      return null;

    case FAILED_CREATE_SUBMISSION:
      return {
        message: action.payload
      };

    default:
      return state;
  }
};

export default errorReducer;
