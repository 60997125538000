export const FILTER_CHANGED = 'FILTER_CHANGED';
export const filterChanged = (key, newRows, newConfiguration) => {
  return {
    type: FILTER_CHANGED,
    payload: {
      key,
      newRows,
      newConfiguration
    }
  };
};

export const FILTERS_LOADED = 'FILTERS_LOADED';
export const filtersLoaded = filters => {
  return {
    type: FILTERS_LOADED,
    payload: {
      filters
    }
  };
};
