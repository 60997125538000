import {
  FAILED_GET_RECORDING,
  RECEIVE_DELETE_RECORDING,
  RECEIVE_GET_RECORDING,
  RECEIVE_RECORDINGS,
  RECEIVE_REVIEWS,
  REQUEST_GET_RECORDING,
  UPDATE_RECORDING
} from 'actions/recordingActions';
import { indexBy } from './helpers';

export const initialState = {
  collection: {},
  uploads: [],
  reviews: [],
  fetching: true,
  error: null
};

export default function recordingReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_GET_RECORDING:
      return {
        ...state,
        fetching: true,
        error: null
      };

    case RECEIVE_GET_RECORDING:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload['id']]: action.payload
        },
        error: null,
        fetching: false
      };

    case FAILED_GET_RECORDING:
      return {
        ...state,
        error: action.payload,
        fetching: false
      };

    case RECEIVE_RECORDINGS:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...indexBy(
            action.payload.map(item => {
              return {
                ...item,
                location: null
              };
            }),
            item => item.id
          )
        },
        fetching: false,
        error: null,
        uploads: action.payload.map(item => item.id)
      };

    case RECEIVE_REVIEWS:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...indexBy(
            action.payload.map(item => {
              return {
                ...item,
                location: null
              };
            }),
            item => item.id
          )
        },
        fetching: false,
        error: null,
        reviews: action.payload.map(item => item.id)
      };

    case UPDATE_RECORDING:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload.id]: action.payload
        }
      };
    case RECEIVE_DELETE_RECORDING:
      delete state.collection[action.recordingId];
      return {
        ...state
      };
    default:
      return state;
  }
}
