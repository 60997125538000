import {
  REQUEST_SEND_PORTFOLIO,
  failedSendPortfolio
} from 'actions/portfolioActions';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import hydraParser from 'service/hydraParser';
import { makeSecureApiCall } from './api';
import { updateRecording } from '../actions/recordingActions';

function* sendPortfolio(action) {
  try {
    const recording = yield call(
      makeSecureApiCall,
      `/api/recordings/${action.data.payload.id}/send-to-portfolio`,
      'GET'
    );
    const parsed = hydraParser.parseItem(recording);
    yield put(updateRecording(parsed));
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedSendPortfolio(parsed));
  }
}

export default function* portfolioSaga() {
  yield all([yield takeLatest(REQUEST_SEND_PORTFOLIO, sendPortfolio)]);
}
