import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * A simple loading indicator.
 */
class Spinner extends PureComponent {
  render() {
    const { pastDelay } = this.props;

    if (pastDelay)
      return (
        <div className="spinner-loader">
          <div className="spinner-border text-primary">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );

    return null;
  }
}

Spinner.propTypes = {
  /** The spinner shouldn't be shown if loading takes less than the blink of an eye. */
  pastDelay: PropTypes.bool
};

Spinner.defaultProps = {
  pastDelay: false
};

export default Spinner;
