import {
  FAILED_GET_VIDEO,
  RECEIVE_GET_VIDEO,
  REQUEST_GET_VIDEO
} from 'actions/videoActions';

const initialState = {
  collection: {},
  error: null,
  fetching: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_GET_VIDEO:
      return {
        ...state,
        fetching: true
      };
    case RECEIVE_GET_VIDEO:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.payload['id']]: action.payload
        },
        fetching: false
      };
    case FAILED_GET_VIDEO:
      return {
        ...state,
        error: action.payload,
        fetching: false
      };
    default:
      return state;
  }
}
