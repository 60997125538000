// Create annotation member
export const REQUEST_CREATE_ANNOTATION = 'REQUEST_CREATE_ANNOTATION';
export const newAnnotation = data => {
  return { type: REQUEST_CREATE_ANNOTATION, data };
};

export const RECEIVE_CREATE_ANNOTATION = 'RECEIVE_CREATE_ANNOTATION';
export const FAILED_CREATE_ANNOTATION = 'FAILED_CREATE_ANNOTATION';

export const receiveCreateAnnotation = payload => ({
  type: RECEIVE_CREATE_ANNOTATION,
  payload,
  recordingId: payload.recording.split('/')[3]
});

export const failedCreateAnnotation = payload => ({
  type: FAILED_CREATE_ANNOTATION,
  payload
});

// Get annotations collection
export const REQUEST_GET_ANNOTATIONS = 'REQUEST_GET_ANNOTATIONS';
export const RECEIVE_GET_ANNOTATIONS = 'RECEIVE_GET_ANNOTATIONS';
export const FAILED_GET_ANNOTATIONS = 'FAILED_GET_ANNOTATIONS';

export const receiveGetAnnotations = payload => ({
  type: RECEIVE_GET_ANNOTATIONS,
  payload
});

export const failedGetAnnotations = payload => ({
  type: FAILED_GET_ANNOTATIONS,
  payload
});

// Delete annotation member
export const REQUEST_DELETE_ANNOTATION = 'REQUEST_DELETE_ANNOTATION';
export const deleteAnnotation = id => {
  return { type: REQUEST_DELETE_ANNOTATION, annotationId: id };
};
export const RECEIVE_DELETE_ANNOTATION = 'RECEIVE_DELETE_ANNOTATION';
export const FAILED_DELETE_ANNOTATION = 'FAILED_DELETE_ANNOTATION';

export const receiveDeleteAnnotation = annotationId => ({
  type: RECEIVE_DELETE_ANNOTATION,
  annotationId: annotationId
});

export const failedDeleteAnnotation = payload => ({
  type: FAILED_DELETE_ANNOTATION,
  payload
});

// Update annotation member
export const REQUEST_UPDATE_ANNOTATION = 'REQUEST_UPDATE_ANNOTATION';
export const RECEIVE_UPDATE_ANNOTATION = 'RECEIVE_UPDATE_ANNOTATION';
export const FAILED_UPDATE_ANNOTATION = 'FAILED_UPDATE_ANNOTATION';

export const receiveUpdateAnnotation = payload => ({
  type: RECEIVE_UPDATE_ANNOTATION,
  payload,
  recordingId: payload.recording.split('/')[3]
});

export const failedUpdateAnnotation = payload => ({
  type: FAILED_UPDATE_ANNOTATION,
  payload
});
