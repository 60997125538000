import {
  all,
  call,
  select,
  put,
  takeLatest,
  takeEvery
} from 'redux-saga/effects';

import {
  failedCreateSubmission,
  failedGetSubmissions,
  receiveCreateSubmission,
  receiveGetSubmissions,
  submissionRequested,
  REQUEST_CREATE_SUBMISSION,
  SUBMISSION_REQUIRED,
  REQUEST_GET_SUBMISSIONS
} from 'actions/submissionActions';
import hydraParser from 'service/hydraParser';
import { makeSecureApiCall } from './api';
import { submissionExists } from '../selectors/Submission/submission-selectors';

function* getSubmissions(action) {
  try {
    const submissions = yield call(
      makeSecureApiCall,
      `/api/recordings/${action.recordingId}/submissions`
    );
    const parsed = hydraParser.parseCollection(submissions);
    yield put(receiveGetSubmissions(parsed));
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedGetSubmissions(parsed));
  }
}

function* requireSubmission(action) {
  try {
    const {
      payload: { submissionId }
    } = action;
    const submission = yield select(
      state => state.submission.collection[submissionId]
    );

    if (!submission || submission.fetching === false) {
      yield put(submissionRequested(submissionId));
      const response = yield call(makeSecureApiCall, submissionId);

      yield put(receiveGetSubmissions([hydraParser.parseItem(response)]));
    }
  } catch (error) {
    yield put(failedGetSubmissions(error));
  }
}

function* submit(action, url) {
  let submission = {
    'hydra:description':
      'Er is iets misgegaan bij de communicatie met de server.'
  };
  try {
    submission = yield call(
      makeSecureApiCall,
      url,
      'POST',
      action.data.payload
    );
    const parsed = hydraParser.parseItem(submission);
    yield put(receiveCreateSubmission(parsed));
  } catch (error) {
    yield put(failedCreateSubmission(submission['hydra:description']));
  }
}

function* handleSubmissionRequest(action) {
  const { supervisor, recordingId } = action.data.payload;

  const exists = yield select(state => {
    const recording = state.recording.collection[recordingId];
    return submissionExists(state, recording, supervisor);
  });

  if (exists) {
    yield submit(action, `${action.data.payload.recording}/resubmit`);
  } else {
    yield submit(action, `${action.data.payload.recording}/submit`);
  }

  action.data.push('/');
}

export default function* submissionSaga() {
  yield all([
    yield takeEvery(REQUEST_GET_SUBMISSIONS, getSubmissions),
    yield takeEvery(SUBMISSION_REQUIRED, requireSubmission),
    yield takeLatest(REQUEST_CREATE_SUBMISSION, handleSubmissionRequest)
  ]);
}
