import React from 'react';
import { PropTypes } from 'prop-types';
import logo from '../../assets/logo.svg';
import Button from 'components/Button';
import { Dropdown } from 'react-bootstrap';

import './style.scss';

/**
 * Represents the top navigation bar.
 */
export default function Navigation({ user, backButton, logout }) {
  const LogoutButton = props => {
    const { logout } = props;

    if (logout === null) {
      return null;
    }

    return (
      <Dropdown>
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          <div className="navbar-toggler" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu alignRight>
          <Dropdown.Item href="//invidii.com"> Hoe werkt het? </Dropdown.Item>
          <Dropdown.Item href="//invidii.com">
            Gebruikersvoorwaarden
          </Dropdown.Item>
          <Dropdown.Item onClick={logout}>Uitloggen</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const LoginStatus = props => {
    const { logout } = props;

    if (user === null) {
      return null;
    }

    return (
      <div className="login-status">
        <div className="login-status__user">
          Ingelogd als
          <div className="login-status__user__username">
            {user && user.username}
          </div>
        </div>
        <div className="login-status__menu">
          <LogoutButton user={user} logout={logout} />
        </div>
      </div>
    );
  };

  return (
    <header className="status-bar navbar">
      <div className="navbar-left">
        {backButton && (
          <Button variant="back-to-dashboard" to="/">
            Dashboard
          </Button>
        )}
      </div>

      <div className="logo">
        <img src={logo} alt={'logo'} />
      </div>

      <div className="navbar-right">
        <LoginStatus logout={logout} />
      </div>
    </header>
  );
}

Navigation.propTypes = {
  /** The current user. Defaults to null if he isn't logged in. */
  user: PropTypes.shape({
    username: PropTypes.string.isRequired
  }),

  /** A way to log out */
  logout: PropTypes.func
};

Navigation.defaultProps = {
  backButton: null,
  user: null,
  logout: null
};
