export const SERVER_ERROR = 'SERVER_ERROR';
export const serverError = message => {
  return {
    type: SERVER_ERROR,
    payload: {
      message
    }
  };
};

export const ERROR_CLEARED = 'ERROR_CLEARED';
export const errorCleared = () => {
  return {
    type: ERROR_CLEARED,
    payload: null
  };
};
