const invidiiApi = {
  open: resource => {
    window.location = process.env.REACT_APP_INVIDII_URL + resource;
  },

  get: async (resource, token) => {
    const response = await fetch(process.env.REACT_APP_INVIDII_URL + resource, {
      method: 'GET',
      headers: {
        Accept: 'application/ld+json',
        Authorization: 'Bearer ' + token
      }
    });

    return response.json();
  },

  getResponse: (token, method, resource, data) => {
    const allowedMethods = ['DELETE', 'GET', 'POST', 'PUT'];
    const methodsWithRequestBody = ['POST', 'PUT'];

    if (method && allowedMethods.indexOf(method) === -1) {
      throw new Error(`${method} method now permitted.`);
    }

    return fetch(process.env.REACT_APP_INVIDII_URL + resource, {
      method: method ? method : 'GET',
      headers: {
        Accept: 'application/ld+json',
        Authorization: 'Bearer ' + token,
        ...(methodsWithRequestBody.indexOf(method) > -1 && data
          ? { 'Content-Type': 'application/json' }
          : {})
      },
      ...(methodsWithRequestBody.indexOf(method) > -1 && data
        ? { body: JSON.stringify(data) }
        : {})
    }).then(response => {
      if (response.ok === false) {
        return response.json().then(reason => {
          throw reason;
        });
      }
      if (method !== 'DELETE') {
        return response.json();
      }
      return true;
    });
  },

  stream: (token, resource) => {
    return fetch(process.env.REACT_APP_INVIDII_URL + resource, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(response => response.blob());
  }
};

export default invidiiApi;
