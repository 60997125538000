import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  select
} from 'redux-saga/effects';

import {
  failedDeleteRecording,
  failedGetRecording,
  failedPutRecording,
  receiveDeleteRecording,
  receiveGetRecording,
  receiveRecordings,
  receiveReviews,
  REQUEST_DELETE_RECORDING,
  REQUEST_GET_RECORDING,
  REQUEST_RECORDINGS,
  REQUEST_REVIEWS
} from 'actions/recordingActions';
import { reviewComplete, SUBMIT_REVIEW } from 'actions/submissionActions';
import hydraParser from 'service/hydraParser';
import { makeSecureApiCall } from './api';
import { reviewSubmissionFailed } from '../actions/submissionActions';
import { UPDATE_RECORDING } from '../actions/recordingActions';

function* getRecording(action) {
  try {
    let recording = yield select(state => state.recording[action.id]);
    if (recording === undefined) {
      recording = yield call(makeSecureApiCall, `/api/recordings/${action.id}`);
      recording = hydraParser.parseItem(recording);
    }
    yield put(receiveGetRecording(recording));
  } catch (error) {
    yield put(failedGetRecording(hydraParser.parseItem(error)));
  }
}

function* getRecordings() {
  try {
    const recordings = yield call(makeSecureApiCall, '/api/recordings');
    if (recordings) {
      const parsed = hydraParser.parseCollection(recordings);
      yield put(receiveRecordings(parsed));
    }
  } catch (error) {
    console.log(error);
    yield put(failedGetRecording(hydraParser.parseItem(error)));
  }
}

function* getReviews() {
  try {
    const recordings = yield call(makeSecureApiCall, '/api/recordings/reviews');
    if (recordings) {
      const parsed = hydraParser.parseCollection(recordings);
      yield put(receiveReviews(parsed));
    }
  } catch (error) {
    console.log(error);
    yield put(failedGetRecording(hydraParser.parseItem(error)));
  }
}

function* submitReview(action) {
  const { submission, reviews } = action.payload;

  try {
    const answeredSubmission = yield call(
      makeSecureApiCall,
      submission['@id'],
      'PUT',
      {
        ...submission,
        reviews
      }
    );

    yield put(reviewComplete(answeredSubmission));
  } catch (error) {
    yield put(reviewSubmissionFailed(error.message));
  }
}

function* updateRecording(action) {
  const { payload } = action;

  {
    /*  try {
    yield call(makeSecureApiCall, payload['@id'], 'PUT', payload);
  } catch (error) {
    yield put(failedPutRecording(error));
  }*/
  }
}

function* deleteRecording(action) {
  try {
    yield call(
      makeSecureApiCall,
      `/api/recordings/${action.recording.id}`,
      'DELETE'
    );
    yield put(receiveDeleteRecording(action.recording.id));
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedDeleteRecording(parsed));
  }
}

export default function* recordingSaga() {
  yield all([
    yield takeLatest(REQUEST_GET_RECORDING, getRecording),
    yield takeLatest(REQUEST_RECORDINGS, getRecordings),
    yield takeLatest(REQUEST_REVIEWS, getReviews),
    yield takeEvery(SUBMIT_REVIEW, submitReview),
    yield takeLatest(UPDATE_RECORDING, updateRecording),
    yield takeLatest(REQUEST_DELETE_RECORDING, deleteRecording)
  ]);
}
