import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux';

import enhancedReducer from './enhancedReducer';
import annotation from './annotationReducer';
import login from './loginReducer';
import recording from './recordingReducer';
import submission from './submissionReducer';
import video from './videoReducer';
import users from './userReducer';
import supervisors from './supervisorsReducer';
import reactions from './reactionReducer';
import filters from './filterReducer';
import error from './errorReducer';

const appReducer = combineReducers({
  annotation,
  login,
  recording,
  router,
  submission,
  video,
  users,
  supervisors,
  reactions,
  filters,
  error
});

export default (state, action) => enhancedReducer(appReducer, state, action);
