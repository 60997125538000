import { call, put, select } from 'redux-saga/effects';
import { serverError, errorCleared } from '../actions/errorActions';

import api from 'service/invidii-api';

export function* makeSecureApiCall(uri, method, data, stream = false) {
  const state = yield select(state => state);
  let response;

  try {
    yield put(errorCleared());

    if (stream) {
      response = yield call(api.stream, state.login.token, uri);
    } else {
      response = yield call(
        api.getResponse,
        state.login.token,
        method,
        uri,
        data
      );
    }

    return response;
  } catch (error) {
    if (error.code === 401) {
      yield put({ type: 'TOKEN_REMOVED' });
    } else {
      yield put(serverError());
    }
    return error;
  }
}
