import React from 'react';
import Spinner from './spinner';

const FetchedItem = props => {
  const { item, children } = props;

  if (item === null || item === undefined) {
    return null;
  }

  if (item.fetching !== undefined) {
    return <Spinner pastDelay={true} />;
  }

  return children(item);
};

export default FetchedItem;
