import React, { PureComponent } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './style.scss';

import Working from './working.svg';

class Button extends PureComponent {
  prepareProps() {
    const { fill, variant, style, working, ...otherProps } = this.props;
    const fillIsColor = fill && fill.match(/^(#|rgb?a|transparent)/i);
    const styles = {
      ...(fillIsColor ? { backgroundColor: fill } : {}),
      ...style
    };
    const classNames = [
      'btn',
      working ? 'working' : null,
      variant ? variant : null,
      fill && !fillIsColor ? `${fill}` : null
    ]
      .join(' ')
      .replace(/\s+/, ' ')
      .trim();

    return {
      ...otherProps,
      styles,
      classNames
    };
  }

  render() {
    const { skeleton, to } = this.props;

    // If skeleton return skeleton
    if (skeleton) return <ButtonSkeleton />;

    // If to, return router link
    if (to) return <ButtonLink {...this.prepareProps()} />;

    // All other cases return "regular" button
    return <ButtonElement {...this.prepareProps()} />;
  }
}

class ButtonSkeleton extends PureComponent {
  render() {
    return <div className="btn skeleton" />;
  }
}

class ButtonLink extends PureComponent {
  render() {
    const { children, classNames, disabled, onClick, styles, to } = this.props;

    // If disabled render regular disabled button (can't disable anchor)
    if (disabled)
      return (
        <button
          disabled="disabled"
          className={classNames}
          style={styles}
          onClick={e => e.preventDefault()}
        >
          {children}
        </button>
      );

    // Render anchor
    return (
      <Link
        className={classNames}
        to={to}
        style={styles}
        onClick={e => onClick(e)}
      >
        {children}
      </Link>
    );
  }
}

class ButtonElement extends PureComponent {
  render() {
    const {
      children,
      classNames,
      disabled,
      onClick,
      styles,
      working
    } = this.props;

    return (
      <button
        disabled={disabled || working ? 'disabled' : null}
        className={classNames}
        style={styles}
        onClick={e => onClick(e)}
      >
        {working && <Working id="workingIcon" />}

        {!working && children}
      </button>
    );
  }
}

Button.defaultProps = {
  onClick: () => {},
  skeleton: false,
  styles: {},
  to: null,
  variant: null,
  working: false
};

Button.propTypes = {
  onClick: PropTypes.func,
  skeleton: PropTypes.bool,
  styles: PropTypes.object,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object
    })
  ]),
  variant: PropTypes.string,
  working: PropTypes.bool
};

export default withRouter(Button);
