import LoginRequiredContainer from 'containers/LoginRequired';
import { Spinner } from 'components/Spinner';
import LoginSuccess from 'containers/LoginSuccess';
import React, { Fragment, useEffect } from 'react';
import Loadable from 'react-loadable';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Footer = Loadable({
  loader: () => import('components/Footer'),
  loading: Spinner
});

const RecordingOverview = Loadable({
  loader: () => import('pages/RecordingOverview'),
  loading: Spinner
});

const VideoDetail = Loadable({
  loader: () => import('containers/VideoDetail'),
  loading: Spinner
});

export default props => {
  useEffect(() => {
    const { init } = props;
    init();
  }, []);

  return (
    <Route
      render={({ location }) => (
        <Fragment>
          <div className="page transition-wrapper">
            <TransitionGroup>
              <CSSTransition key={location.key} classNames="fade" timeout={300}>
                <Switch location={location}>
                  <Route
                    exact
                    strict
                    path="/login-success/:token"
                    component={LoginSuccess}
                  />

                  <Route
                    exact
                    strict
                    path="/"
                    component={LoginRequiredContainer(RecordingOverview)}
                  />

                  <Route
                    exact
                    strict
                    path="/recording/:id"
                    component={LoginRequiredContainer(VideoDetail)}
                  />

                  {/* Redirect to / if no match */}
                  <Redirect to="/" />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>

          <Footer />
        </Fragment>
      )}
    />
  );
};
