import { all, put, select, takeLatest } from 'redux-saga/effects';
import { FILTER_CHANGED, filtersLoaded } from '../actions/filterActions';
import { APP_STARTED } from '../actions/appActions';
import Session from '../service/session';
import { LOGOUT, LOGOUT_COMPLETE } from '../actions/loginActions';

function* saveFilters() {
  const filters = yield select(state => state.filters);

  Session.saveFilters(filters);
}

function* loadFilters() {
  const filters = Session.loadFilters();

  if (filters !== null) {
    yield put(filtersLoaded(filters));
  }
}

export default function* filterSaga() {
  yield all([
    yield takeLatest(FILTER_CHANGED, saveFilters),
    yield takeLatest(APP_STARTED, loadFilters),
    yield takeLatest(LOGOUT, saveFilters),
    yield takeLatest(LOGOUT_COMPLETE, saveFilters)
  ]);
}
