export const LOGOUT = 'LOGOUT';
export const logout = () => {
  return {
    type: LOGOUT
  };
};

export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE';
export const logoutComplete = () => {
  return {
    type: LOGOUT_COMPLETE
  };
};

export default {
  LOGOUT,
  logout,
  LOGOUT_COMPLETE,
  logoutComplete
};
