export const FAILED_GET_RECORDING = 'FAILED_GET_RECORDING';
export const FAILED_PUT_RECORDING = 'FAILED_PUT_RECORDING';
export const RECEIVE_GET_RECORDING = 'RECEIVE_GET_RECORDING';
export const RECEIVE_RECORDINGS = 'RECEIVE_RECORDINGS';
export const RECEIVE_SUBMISSION = 'RECEIVE_SUBMISSION';
export const REQUEST_GET_RECORDING = 'REQUEST_GET_RECORDING';
export const REQUEST_RECORDINGS = 'REQUEST_RECORDINGS';
export const UPDATE_RECORDING = 'UPDATE_RECORDING';
export const REQUEST_REVIEWS = 'REQUEST_REVIEWS';
export const RECEIVE_REVIEWS = 'RECEIVE_REVIEWS';
export const REQUEST_DELETE_RECORDING = 'REQUEST_DELETE_RECORDING';
export const DELETE_RECORDING = 'DELETE_RECORDING';
export const RECEIVE_DELETE_RECORDING = 'RECEIVE_DELETE_RECORDING';
export const FAILED_DELETE_RECORDING = 'FAILED_DELETE_RECORDING';

export function failedGetRecording(payload) {
  return {
    type: FAILED_GET_RECORDING,
    payload
  };
}

export function failedPutRecording(payload) {
  return {
    type: FAILED_PUT_RECORDING,
    payload: {
      error: payload
    }
  };
}

export function receiveGetRecording(payload) {
  return {
    type: RECEIVE_GET_RECORDING,
    payload
  };
}

export function receiveSubmission(submission) {
  return {
    type: RECEIVE_SUBMISSION,
    payload: submission
  };
}

export function receiveRecordings(recordings) {
  return {
    type: RECEIVE_RECORDINGS,
    payload: recordings
  };
}

export function receiveReviews(recordings) {
  return {
    type: RECEIVE_REVIEWS,
    payload: recordings
  };
}

export function updateRecording(recording) {
  return {
    type: UPDATE_RECORDING,
    payload: recording
  };
}

export function deleteRecording(recording) {
  return {
    type: DELETE_RECORDING,
    payload: recording
  };
}
export const receiveDeleteRecording = recordingId => ({
  type: RECEIVE_DELETE_RECORDING,
  recordingId: recordingId
});

export const failedDeleteRecording = payload => ({
  type: FAILED_DELETE_RECORDING,
  payload
});
