export const submissionExists = (state, recording, supervisorId) => {
  return recording.submissions.some(submissionId => {
    return (
      state.submission.collection[submissionId].supervisor === supervisorId
    );
  });
};

export default {
  submissionExists
};
