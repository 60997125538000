import {
  FAILED_GET_SUPERVISORS,
  RECEIVE_GET_SUPERVISORS,
  REQUEST_GET_SUPERVISORS
} from 'actions/supervisorActions';

export const initialState = {
  collection: {},
  error: null,
  fetching: true
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_GET_SUPERVISORS:
      return {
        ...state,
        error: null,
        fetching: true
      };
    case RECEIVE_GET_SUPERVISORS:
      return {
        ...state,
        collection: action.payload === [] ? {} : action.payload,
        error: null,
        fetching: false
      };
    case FAILED_GET_SUPERVISORS:
      return {
        ...state,
        error: action.payload,
        fetching: false
      };
    default:
      return state;
  }
}
