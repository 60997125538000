import { updateRecording } from 'actions/recordingActions';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  failedDeleteVideo,
  failedGetVideo,
  receiveDeleteVideo,
  receiveGetVideo,
  REQUEST_DELETE_VIDEO,
  REQUEST_GET_VIDEO
} from 'actions/videoActions';
import hydraParser from 'service/hydraParser';
import { makeSecureApiCall } from './api';

function* getVideo(action) {
  try {
    const video = yield call(
      makeSecureApiCall,
      `/api/recordings/${action.recordingId}/stream`,
      null,
      null,
      true
    );
    yield put(receiveGetVideo(video));
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedGetVideo(parsed));
  }
}

function* deleteVideo(action) {
  try {
    const video = yield call(
      makeSecureApiCall,
      `/api/recordings/${action.recording.id}`,
      'PUT',
      { ...action.recording, video: null }
    );
    yield put(receiveDeleteVideo(video));
    yield put(updateRecording({ ...action.recording, video: null }));
  } catch (error) {
    const parsed = hydraParser.parseItem(error);
    yield put(failedDeleteVideo(parsed));
  }
}

export default function* videoSaga() {
  yield all([
    yield takeLatest(REQUEST_GET_VIDEO, getVideo),
    yield takeLatest(REQUEST_DELETE_VIDEO, deleteVideo)
  ]);
}
