const defaultState = {
  token: null,
  user: null
};

export default function loginReducer(state = defaultState, action) {
  switch (action.type) {
    case 'TOKEN_RECEIVED':
      const token = action.payload.token;
      const user = JSON.parse(atob(token.split('.')[1]));
      const newState = { ...state, token, user };
      localStorage.setItem('state', JSON.stringify({ login: newState }));
      return newState;
    case 'TOKEN_REMOVED':
      const logout = { ...state, token: null, user: null };
      localStorage.setItem('state', JSON.stringify({ login: logout }));
      return logout;
    default:
      return state;
  }
}
