import { FILTER_CHANGED, FILTERS_LOADED } from '../actions/filterActions';
import { LOGOUT, LOGOUT_COMPLETE } from '../actions/loginActions';

const defaultState = {};

const updateFilter = (state, { key, newRows, newConfiguration }) => {
  return {
    ...state,
    [key]: {
      rows: newRows,
      configuration: newConfiguration
    }
  };
};

const filterReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FILTER_CHANGED:
      return updateFilter(state, payload);

    case FILTERS_LOADED:
      return action.payload.filters;

    case LOGOUT:
      return defaultState;

    case LOGOUT_COMPLETE:
      return defaultState;

    default:
      return state;
  }
};

export default filterReducer;
