const throwError = response => {
  throw new Error(
    `${response['hydra:title']}: ${response['hydra:description']}`
  );
};

const hydraParser = {
  parseCollection: response => {
    if (response['@type'] === 'hydra:Error') throwError(response);

    if (response['@type'] === 'hydra:Collection') {
      return response['hydra:member'];
    }
  },
  parseItem: response => {
    if (response['@type'] === 'hydra:Error') throwError(response);

    if (response['@type'] !== 'hydra:Collection') {
      delete response['@context'];
      return response;
    }

    return response;
  }
};

export default hydraParser;
