const USER_REQUIRED = 'USER_REQUIRED';
const USER_RECEIVED = 'USER_RECEIVED';

const userRequired = userId => {
  return {
    type: USER_REQUIRED,
    payload: { userId }
  };
};

const USER_REQUESTED = 'USER_REQUESTED';
const userRequested = userId => {
  return {
    type: USER_REQUESTED,
    payload: { userId }
  };
};

const userLoaded = userData => {
  return {
    type: USER_RECEIVED,
    payload: userData
  };
};

export {
  USER_REQUIRED,
  USER_RECEIVED,
  USER_REQUESTED,
  userRequired,
  userLoaded,
  userRequested
};
