import {
  USER_RECEIVED,
  USER_REQUIRED,
  USER_REQUESTED
} from 'actions/userActions';

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RECEIVED:
      return {
        ...state,
        [action.payload['@id']]: action.payload
      };

    case USER_REQUIRED:
      if (state[action.payload['userId']] !== undefined) {
        return state;
      }
      return {
        ...state,
        [action.payload['userId']]: { fetching: false }
      };

    case USER_REQUESTED:
      if (state[action.payload['userId']].fetching === false) {
        return {
          ...state,
          [action.payload['userId']]: { fetching: true }
        };
      }
      return state;

    default:
      return state;
  }
};

export default userReducer;
